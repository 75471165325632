<template>
    <div class="backgroups" >
        <el-row>
            <el-header class="title">注册绑定</el-header>
        </el-row>
        <el-row :style="{minHeight: Height+'px'}">
<el-col :span="6" :offset="9">
                <div class="form">
        <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="用户姓名">
       <el-col :span="20">
    <el-input v-model="form.name"></el-input>
       </el-col>
  </el-form-item>
  <el-form-item label="手机号码">
       <el-col :span="20">
     <el-input v-model="form.mobile"></el-input>
    
        </el-col>
         <el-col :span="4">
              <!-- <el-button type="info" icon="el-icon-message" circle   @click="try_sms">验证码</el-button> -->
       </el-col>
       </el-form-item>

        <el-form-item>
           <el-col :span="20">
     <!-- <el-input v-model="form.dxyzm"></el-input> -->
     
       </el-col>
       </el-form-item>
    

  <el-form-item label="邀请码">
    <el-col :span="20">
        <el-input v-model="form.code"></el-input>
    </el-col>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">绑定</el-button>
    <el-button @click="cancelFunction">取消</el-button>
  </el-form-item>
</el-form>
                </div>
</el-col>

        </el-row>
 <footer>
     <div>
             Copyright @ 2020-2021 福州通和信计算机有限公司
         </div>
         <div>网站备案号：<el-link href="https://beian.miit.gov.cn/" >闽ICP备18022481号-2</el-link></div></footer>
</div>
</template>
<script>
import  md5 from '../components/common/js/allSchool.js'
import {PostobjKeySort}  from '../components/common/js/printHelp.js'
import  sms from '../components/common/js/allSchool.js'
export default{

    data(){
        return{
            form:{
                name:"",
                mobile:"",
                code:"",
                unionid:"",
                ip:""
            },
            Height: 0
        }

        },
        mounted(){
           
  
            this.getOpenId()
            //this.setLabopenid()
            this.Height = document.documentElement.clientHeight - 100;  
　　//监听浏览器窗口变化　
            window.onresize = ()=> {this.Height = document.documentElement.clientHeight -100}
        }
        ,methods:{
            //调用短信验证
            try_sms(){
                let url="https://service.winic.org/sys_port/gateway/index.asp"
                let parm={
                    id:"曾凌峰",
                    pwd:"th22285556",
                    to:this.mobile,
                    content:"你的验证码,[1234]",
                }

                this.$axios.post(
                    
                    url,
                parm,
               {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            ).then(res=>{
                    console.log("res" ,res)
                })
            },
        

           
            //get openid info
            getOpenId(){   
               
                 let url='http://api.thsen.top:15001/getip?cust_name=3333333333';
                     
                     let par={cust_name:3333333333}
           
                this.$axios.get(url)
                .then(res => {  
                    this.form.ip= res.data.IP
                   console.log("ip",this.form.ip)

                    })                                 

                if(this.$route.query.state==='')
                {
            //let url='http://www.thsen.top:15001/getip?cust_name=3333333333';
            

            let par={cust_name:3333333333}
            //this.reqM1Service('http://www.thsen.top:15001/getip',par,'get')
            this.$axios.get(url)
            .then(res => { 
                
                let query={}
                  query= {
                     ip:this.form.ip,
                     code:this.$route.query.code,                  
                   }
                let newkey = PostobjKeySort(query) 
                   let sign= md5.M5(newkey.md5s)
                    let url="/SCM/GetUnionIDByCode2?sign="
                   this.reqM1Service(url+sign,newkey.par,'post')
                   // this.$axios.post(url+sign,newkey.par)
                    .then((res=>{
                        console.log("手动绑定进入后",res)
                       this.form.unionid= res.data.unionid
                       this.form.name=res.data.name
                       this.form.mobile=res.data.mobile
                    }))
                }) 
         
                }
                else //如果不是手动绑定方式进入
                {
                    
                    
                }
                
            },

            cancelFunction(){
                this.$router.push("./login");
            },


            ///绑定openid 和code
            onSubmit(){

                 console.log("onsubmit ip is ", this.form.ip)

                    let query={
                        unionid:this.form.unionid,
                        verify:this.form.code,
                        mobile:this.form.mobile,
                        name:this.form.name,
                        ip:this.form.ip


                    }
                    
                    let newkey = PostobjKeySort(query)  
                    console.log("newkey",newkey)                
                   let sign= md5.M5(newkey.md5s)
                        this.reqM1Service("/SCM/BandInfo?sign="+sign,newkey.par,'post')
                //this.$axios.post("/SCM/BandInfo?sign="+sign,newkey.par)
                    .then((res => {
                        console.log(res)
                       if(res.data.code===200) {
                          if(this.$route.query.state==='control') 
                           ////bind sucess to login
                           {this.$router.push("./control_login")}
                           else{
                               this.$router.push("./login")
                           }
                           
                       }
                       else{
                        this.$alert('<strong>绑定失败</strong>', '消息', {
                            dangerouslyUseHTMLString: true
        });
                       }
                       
                    })) 
                   
            }
        }
        }
</script>
<style>
.backgroups {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/img/login_back.jpg);
        background-size: 100%
    }
.title{
    margin-top: 30px;
    text-align: center;
    font-size: 30px;
}
.form{
    border: 2px solid black ;
    text-align: center;
    padding-top: 30px;
}

       
  el-row{
      scroll-padding-bottom: 200px;
  }
  
footer{height:50px;margin-top:-50px;
        background-color: #B3C0D1;
            color: #333;
            text-align: center;}

</style>